import payload_plugin_CGl2wLxRWK from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_eyE4iC3jFi from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u9oDq6ZY14 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2k7poPJp8g from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8CkfZ94Bzr from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass_0318465db62afdda785796958920f633/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import directus_fdYmlw0WUJ from "/app/plugins/directus.ts";
import payload_client_wf4EizJnHH from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PjdA7D4yDO from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aBedeAT0O5 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_clNZ1wvqwK from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xTsjiNNEGr from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_idbTXYtJTb from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KL8mElszQT from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.0_@type_dc490384979bdd687c8a388e333cc36b/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import siteConfig_34133UIzQ4 from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass-e_12e541264bcedab6c449ae6b7f09633a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_mfweii8337 from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass-e_12e541264bcedab6c449ae6b7f09633a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Jg5WpvQTdo from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass-e_12e541264bcedab6c449ae6b7f09633a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_QTxFJrAp2C from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.1_sass-e_12e541264bcedab6c449ae6b7f09633a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import switch_locale_path_ssr_0tCEg0gc2g from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_6de8e037d05f82d2fac0d1b83c92f0af/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_HnHImCtN4f from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_6de8e037d05f82d2fac0d1b83c92f0af/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_ZkblP2UXcn from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_6de8e037d05f82d2fac0d1b83c92f0af/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import gsap_qE0fizvEy0 from "/app/plugins/gsap.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import sonner_client_l6WBUspFNo from "/app/plugins/sonner.client.ts";
import ssg_detect_Ejk7Dsd5Zh from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_6de8e037d05f82d2fac0d1b83c92f0af/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_CGl2wLxRWK,
  revive_payload_client_eyE4iC3jFi,
  unhead_u9oDq6ZY14,
  router_2k7poPJp8g,
  _0_siteConfig_8CkfZ94Bzr,
  directus_fdYmlw0WUJ,
  payload_client_wf4EizJnHH,
  navigation_repaint_client_PjdA7D4yDO,
  check_outdated_build_client_aBedeAT0O5,
  chunk_reload_client_clNZ1wvqwK,
  plugin_vue3_xTsjiNNEGr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_idbTXYtJTb,
  plugin_KL8mElszQT,
  siteConfig_34133UIzQ4,
  inferSeoMetaPlugin_mfweii8337,
  titles_Jg5WpvQTdo,
  defaultsWaitI18n_QTxFJrAp2C,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_0tCEg0gc2g,
  route_locale_detect_HnHImCtN4f,
  i18n_ZkblP2UXcn,
  plugin_6Ph1iQzNHk,
  gsap_qE0fizvEy0,
  sentry_client_shVUlIjFLk,
  sonner_client_l6WBUspFNo,
  ssg_detect_Ejk7Dsd5Zh
]