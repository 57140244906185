
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46pageSlug_93HaTbXNDuqUMeta } from "/app/pages/[...pageSlug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as configuratorGcPfeVrbgAMeta } from "/app/pages/configurator.vue?macro=true";
import { default as _91slug_93jBuR8M2OHbMeta } from "/app/pages/pools/[slug].vue?macro=true";
import { default as indexqJvsbHT8zZMeta } from "/app/pages/pools/index.vue?macro=true";
import { default as _91slug_93rNUtF0rHPmMeta } from "/app/pages/projects/[slug].vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubV2Njxvp4bfMeta } from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubV2Njxvp4bf } from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.1_db0@0.2.1_eslint@9.20.1_jiti@2.4.2_08e7d23fc8e7970b760931a9e3a5fc07/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "pageSlug___nl",
    path: "/nl/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___fr",
    path: "/fr/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "configurator___nl",
    path: "/nl/configurator",
    component: () => import("/app/pages/configurator.vue")
  },
  {
    name: "configurator___en",
    path: "/en/configurator",
    component: () => import("/app/pages/configurator.vue")
  },
  {
    name: "configurator___fr",
    path: "/fr/configurator",
    component: () => import("/app/pages/configurator.vue")
  },
  {
    name: "configurator___de",
    path: "/de/configurator",
    component: () => import("/app/pages/configurator.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "pools-slug___nl",
    path: "/nl/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue")
  },
  {
    name: "pools-slug___en",
    path: "/en/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue")
  },
  {
    name: "pools-slug___fr",
    path: "/fr/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue")
  },
  {
    name: "pools-slug___de",
    path: "/de/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue")
  },
  {
    name: "pools___nl",
    path: "/nl/pools",
    component: () => import("/app/pages/pools/index.vue")
  },
  {
    name: "pools___en",
    path: "/en/pools",
    component: () => import("/app/pages/pools/index.vue")
  },
  {
    name: "pools___fr",
    path: "/fr/pools",
    component: () => import("/app/pages/pools/index.vue")
  },
  {
    name: "pools___de",
    path: "/de/pools",
    component: () => import("/app/pages/pools/index.vue")
  },
  {
    name: "projects-slug___nl",
    path: "/nl/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___fr",
    path: "/fr/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___de",
    path: "/de/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects___nl",
    path: "/nl/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___fr",
    path: "/fr/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___de",
    path: "/de/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubV2Njxvp4bfMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubV2Njxvp4bf
  }
]